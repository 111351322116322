const Input = {
    variants: {
        outline: {
            field: {
                borderRadius: '0.9375rem',
                paddingTop: '1.75rem',
                paddingBottom: '1.75rem',
                fontWeight: '400',
                fontFamily: "'Ubuntu', sans-serif;",
                borderColor: 'pearl.light',
                boxShadow: '12px 26px 50px rgba(90, 108, 234, 0.07);',
                _invalid: {
                    borderColor: 'purple.400',
                    boxShadow: `0 0 0 1px #D6BCFA`,
                    background: 'purple.50'
                }
            }
        }
    }
};

export default Input;
