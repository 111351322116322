import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import Button from './components/button';
import Checkbox from './components/checkbox';
import FormError from './components/form-error';
import Heading from './components/heading';
import Input from './components/input';
import Modal from './components/modal';
import Select from './components/select';
import Skeleton from './components/skeleton';
import Tag from './components/tag';
import config from './config';
import styles from './styles';

const theme = {
    config,
    colors,
    styles,
    components: {
        Button,
        Checkbox,
        FormError,
        Heading,
        Input,
        Tag,
        Modal,
        Select,
        Skeleton
    }
};

export default extendTheme(theme);
