import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const baseStyleText: SystemStyleFunction = (props) => {
    return {
        color: mode('purple.500', 'purple.500')(props),
        mt: 2,
        fontSize: 'sm',
        lineHeight: 'normal'
    };
};

const baseStyleIcon: SystemStyleFunction = (props) => {
    return {
        marginEnd: '0.5em',
        color: mode('purple.500', 'purple.500')(props)
    };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    text: baseStyleText(props),
    icon: baseStyleIcon(props)
});

const FormError = {
    parts: parts.keys,
    baseStyle
};

export default FormError;
