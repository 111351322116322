import * as Sentry from '@sentry/react';
import { DataStore, Hub } from 'aws-amplify';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Restaurant } from 'src/models';
import { useAuth } from 'src/utils/hooks/useAuth';

type Props = {
    children: ReactNode;
};

type DataStoreContext = {
    isReady: boolean;
};

const DataStoreContext = createContext<DataStoreContext>({
    isReady: false
});

export const DataStoreProvider: FC<Props> = (props) => {
    const { children } = props;
    const { isLogged } = useAuth();
    const [isReady, setIsReady] = useState(false);

    const start = async () => {
        try {
            const removeAuthEventListener = Hub.listen('auth', async (data) => {
                const {
                    payload: { event }
                } = data;
                if (event === 'signOut') {
                    await DataStore.clear();
                    Sentry.configureScope((scope) => scope.setUser(null));
                }
            });

            const removeDataStoreEventListener = Hub.listen('datastore', async (data) => {
                const {
                    payload: { event }
                } = data;
                if (event === 'ready') {
                    setIsReady(true);
                }
            });

            await DataStore.query(Restaurant);

            return () => {
                removeAuthEventListener();
                removeDataStoreEventListener();
            };
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    useEffect(() => {
        if (isLogged()) {
            start();
        }
    }, [isLogged]);

    return <DataStoreContext.Provider value={{ isReady }}>{children}</DataStoreContext.Provider>;
};

export const useDataStore = (): DataStoreContext => useContext(DataStoreContext);
