const colors = {
    pearl: {
        light: '#F4F4F4',
        regular: '#FEFEFF'
    },
    lime: {
        50: '#e1ffec',
        100: '#b8f7cf',
        200: '#8ef0b2',
        300: '#53E88B',
        400: '#38e477',
        500: '#15BE77',
        600: '#53E88B',
        700: '#53E88B',
        800: '#02431d',
        900: '#001804'
    },
    orange: {
        light: '#FFF6ED',
        regular: '#DA6317'
    },
    grey: {
        regular: '#ADADAD'
    }
};

export default colors;
