import { Capacitor } from '@capacitor/core';
import ReactOneSignal from 'react-onesignal';
import { isDevEnv, isPreviewEnv } from 'src/utils/helpers/env';
import { isMobileSafari } from 'src/utils/helpers/isMobileSafari';

export const isOneSignalDisabled = () => {
    return isDevEnv() || isPreviewEnv() || isMobileSafari() || process.env.NEXT_PUBLIC_ONE_SIGNAL_DISABLED === 'true';
}

const init = async () => {
    if (isOneSignalDisabled()) {
        return;
    }

    try {
        const appId = process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID;

        if (!appId) {
            throw new Error('NEXT_PUBLIC_ONE_SIGNAL_APP_ID is not defined');
        }

        if (Capacitor.isNativePlatform()) {
            import('onesignal-cordova-plugin')
                .then((module) => module.default)
                .then((CordovaOneSignal) => {
                    CordovaOneSignal.setAppId(appId);
                    CordovaOneSignal.setNotificationOpenedHandler(() => void 0);
                    CordovaOneSignal.promptForPushNotificationsWithUserResponse(true);
                });
        } else {
            await ReactOneSignal.init({
                appId,
                allowLocalhostAsSecureOrigin: true,
                safari_web_id: process.env.NEXT_PUBLIC_ONE_SIGNAL_SAFARI_WEB_ID,
                serviceWorkerParam: { scope: '/js/push/onesignal/' },
                serviceWorkerPath: 'js/push/onesignal/sw.js',
                notifyButton: {
                    enable: false
                }
            });
            await ReactOneSignal.showNativePrompt();
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const setLanguage = async (language: string) => {
    if (isOneSignalDisabled()) {
        return;
    }

    try {
        if (Capacitor.isNativePlatform()) {
            import('onesignal-cordova-plugin')

                .then((module) => module.default)
                .then((CordovaOneSignal) => {
                    CordovaOneSignal.setLanguage(language);
                });
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const enable = async (username: string) => {
    if (isOneSignalDisabled()) {
        return;
    }

    try {
        if (Capacitor.isNativePlatform()) {
            const CordovaOneSignal = await import('onesignal-cordova-plugin').then(
                (module) => module.default
            );
            CordovaOneSignal.setExternalUserId(username);
            localStorage.setItem('notifications_external_user_id', username);
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const disable = async () => {
    const currentValue = await isEnabled();

    if (!currentValue || isOneSignalDisabled()) {
        return;
    }

    try {
        if (Capacitor.isNativePlatform()) {
            const CordovaOneSignal = await import('onesignal-cordova-plugin').then(
                (module) => module.default
            );
            CordovaOneSignal.removeExternalUserId();
            localStorage.removeItem('notifications_external_user_id');
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const isEnabled = async () => {
    if (isOneSignalDisabled()) {
        return false;
    }

    try {
        if (Capacitor.isNativePlatform()) {
            const externalUserId = localStorage.getItem('notifications_external_user_id');
            return !!externalUserId;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const OneSignal = {
    init,
    setLanguage,
    enable,
    disable,
    isEnabled
};

export default OneSignal;
