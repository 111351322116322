import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

type Auth = {
    attributes: {
        email: string;
    };
    pool: {
        userPoolId: string;
    };
    signInUserSession: {
        accessToken: {
            payload: {
                'cognito:groups': Array<string>;
            };
        };
    };
    username: string;
};

type Props = {
    children: ReactNode;
};

type AuthContext = {
    handleAuth: (user: Auth | null) => void;
    handleCachedAuth: (user: Auth | null) => void;
    isLogged: () => boolean;
    isOrg: () => boolean;
    user: Auth | null | undefined;
    cachedUser: Auth | null | undefined;
};

const AuthContext = createContext<AuthContext>({
    handleAuth: () => {
        return 0;
    },
    handleCachedAuth: () => {
        return 0;
    },
    isLogged: () => {
        return false;
    },
    isOrg: () => {
        return false;
    },
    user: undefined,
    cachedUser: undefined
});

export const AuthProvider: FC<Props> = (props) => {
    const { children } = props;
    const { pathname } = useRouter();
    const [user, setUser] = useState<Auth | null | undefined>();
    const [cachedUser, setCachedUser] = useState<Auth | null | undefined>();

    const handleAuth = (user: Auth | null) => {
        Sentry.setUser({ email: user?.attributes.email, username: user?.username });
        setUser(user);
    };

    const handleCachedAuth = (user: Auth | null) => {
        setCachedUser(user);
    };

    const isLogged = useCallback(() => {
        return Boolean(user?.signInUserSession.accessToken);
    }, [user]);

    const isOrg = useCallback(() => {
        return Boolean(
            user?.signInUserSession?.accessToken?.payload['cognito:groups']?.includes('orgs')
        );
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                handleAuth(user);
            } catch (error) {
                setUser(null);
            }
        };
        fetchData();
    }, [pathname]);

    return (
        <AuthContext.Provider
            value={{ handleAuth, handleCachedAuth, isLogged, isOrg, user, cachedUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContext => useContext(AuthContext);
