import { useEffect } from 'react';

const Logger = () => {
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_LOGGER_DISABLED === 'false') {
            const script = document.createElement('script');
            script.src = 'https://remotejs.com/agent/agent.js';
            script.setAttribute('data-consolejs-channel', 'com.backresto.app');
            document.head.appendChild(script);

            // Clean up the script element when the component is unmounted
            return () => {
                document.head.removeChild(script);
            };
        }
    }, []);

    return null;
};

export default Logger;
