import colors from 'src/theme/colors';

const Button = {
    baseStyle: {
        borderRadius: '0.9375rem',
        paddingTop: '1.75rem',
        paddingBottom: '1.75rem',
        fontWeight: '400',
        fontFamily: "'Ubuntu', sans-serif;"
    },
    variants: {
        black: {
            bg: 'white',
            color: 'black'
        },
        limeClear: {
            bg: 'white',
            color: 'lime.500'
        },
        blackClear: {
            bg: 'white',
            color: 'gray.500'
        },
        orange: {
            bg: 'orange.light',
            color: 'orange.regular'
        },
        solid: {
            bg: `linear-gradient(98.81deg, ${colors.lime[300]} -0.82%, ${colors.lime[500]} 101.53%)`,
            color: 'pearl.regular'
        }
    }
};

export default Button;
