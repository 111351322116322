import colors from 'src/theme/colors';

const Tag = {
    baseStyle: {
        container: {
            paddingTop: '1.1rem',
            paddingBottom: '1.1rem',
            fontWeight: '400',
            fontFamily: "'Ubuntu', sans-serif;"
        }
    },
    variants: {
        solid: {
            container: {
                backgroundColor: 'orange.light',
                color: 'orange.regular',
                border: `1px solid ${colors.orange.light}`,
                boxShadow: 'none'
            }
        },
        outline: {
            container: {
                backgroundColor: 'pearl.regular',
                color: 'orange.regular',
                border: `1px solid ${colors.orange.regular}`,
                boxShadow: 'none'
            }
        }
    },
    sizes: {
        lg: {
            container: {
                fontSize: 'md',
                borderRadius: '0.9375rem',
                boxShadow: 'none'
            }
        }
    }
};

export default Tag;
