/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://oqbobxh5k5hwxkglv7oee5ukau.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ocdubwxq7jhutoshxvlcpfcsgm",
    "aws_cloud_logic_custom": [
        {
            "name": "rest",
            "endpoint": "https://1qrdkcs5fe.execute-api.eu-west-3.amazonaws.com/main",
            "region": "eu-west-3"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-3:c2dbe761-f9ac-4a5e-b28e-55a960051f7c",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_cczcSSkGB",
    "aws_user_pools_web_client_id": "2h1c8sov3r0v81nkrhqf7cba8e",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "backresto-vault224344-main",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
