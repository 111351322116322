var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1f27e6f0db3906cfae98e467d1f783a4fd37f3f7"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: SENTRY_DSN || 'https://7a20a080e5a342deb5ddd26cc0035c5d@o262608.ingest.sentry.io/6255004',
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new Sentry.Replay({
            blockAllMedia: false,
            maskAllText: false,
            maskAllInputs: false
        })
    ]
});
